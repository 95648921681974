import React from 'react';
import { Bed, House, Land, Bath, Note, Floor } from '../../components/images/icons';

const houses = [
  {
    project: 'stelhainstraat',
    alias: 'lot_1',
    name: 'Lot 1',
    title: (
      <React.Fragment>
        <p className='slide-title'>Stelhainstraat</p>
        <p className='slide-title slide-title__lightblue'>Woning 1</p>
      </React.Fragment>
    ),
    location: {
      city: '3401 Walshoutem',
      street: 'Stelhainstraat 9',
    },
    price: '329.526',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>3 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>1 badkamer</p> },
      { icon: <House />, text: <p className='main-text'>165 m&sup2; ruimte</p> },
      { icon: <Land />, text: <p className='main-text'>434 m&sup2; grond</p> },
    ],
    description: {
      title: 'Prachtige energiezuinige halfopen bebouwing',
      text: 'Opp. woning: 155 m², terras: 10.40 m², carport in cederhout: 17.75 m². Gelegen in een rustige straat, op een steenworp van de E40 Luik-Brussel en op 5 minuten van het station in Landen.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Naam Pand', value: 'Stelhain' },
          { description: 'Bouwjaar', value: '2020' },
          { description: 'Breedte Straatgevel', value: '8.2 m' },
          { description: 'Aantal gevels', value: '3' },
          { description: 'Staanplaatsen binnen', value: '1' },
          { description: 'Parkeerplaatsen buiten', value: '1' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '160 m²', rows: 2 },
          { description: 'Oppervlakte Woonkamer', value: '36 m²', rows: 2 },
          { description: 'Eetkamer', value: 'Ja' },
          { description: 'Type Keuken', value: 'Hyper Uitgerust', rows: 2 },
          { description: 'Slaapkamers', value: '3' },
          { description: 'Opp. slaapkamer 1', value: '14 m²' },
          { description: 'Opp. slaapkamer 2', value: '11 m²' },
          { description: 'Opp. slaapkamer 3', value: '13 m²' },
          { description: 'Dressing', value: 'Ja' },
          { description: 'Badkamers', value: '1' },
          { description: 'WC', value: '2' },
          { description: 'Wasplaats', value: 'Ja' },
          { description: 'Oppervlakte kelder', value: '80 m²' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte terrein', value: '434 m²' },
          { description: 'Aansluiting riolering', value: 'Ja' },
          { description: 'Gas, water & elektriciteit', value: 'Ja' },
          { description: 'Oppervlakte tuin', value: '274 m²' },
          { description: 'Oppervlakte terras', value: '10 m²' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [{ description: 'Aangepast aan handicap', value: 'Ja', rows: 2 }],
      },
      {
        title: 'Energie',
        items: [
          { description: 'E-niveau (primair)', value: '77 kWh/m²', rows: 2 },
          { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'Gas' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
          { description: 'Stedenbouwkundige bestemming', value: 'Woongebied ', rows: 2 },
        ],
      },
      {
        title: 'Financieel',
        items: [
          { description: 'Prijs', value: '&euro; 329.526' },
          { description: 'Grondprijs', value: '&euro; 90.000' },
          { description: 'Constructieprijs', value: '&euro; 239.526' },
          { description: 'Registratie', value: '&euro; 9.000' },
          { description: 'BTW op Constructie', value: '&euro; 50.300,46' },
          { description: 'Totaalprijs All-in', value: '&euro; 388.826,46' },
        ],
      },
    ],
  },
  {
    project: 'stelhainstraat',
    alias: 'lot_2',
    name: 'Lot 2',
    title: (
      <React.Fragment>
        <p className='slide-title'>Stelhainstraat</p>
        <p className='slide-title slide-title__lightblue'>Woning 2</p>
      </React.Fragment>
    ),
    location: {
      city: '3401 Walshoutem',
      street: 'Stelhainstraat 7',
    },
    price: '322.526',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>3 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>1 badkamer</p> },
      { icon: <House />, text: <p className='main-text'>165 m&sup2; ruimte</p> },
      { icon: <Land />, text: <p className='main-text'>315 m&sup2; grond</p> },
    ],
    description: {
      title: 'Prachtige energiezuinige halfopen bebouwing',
      text: 'Opp. woning: 155 m², terras: 10.40 m², carport in cederhout: 17.75 m². Volledige "BEN" woning (Bijna Energie Neutraal) te Landen (Walshoutem). Voldoet aan norm E19 hierdoor komende 5 jaar vrijstelling KI t.b.v. ca. € 5.000,-. Voorzien van PV panelen, vloerverwarming, zonwerend glas, extra isolatie, en regenwatervoorziening met pomp. Volledig instap klaar, onmiddellijk beschikbaar bij akte. Afwerking volledig met Carport, oprit, tuin, voortuin en parkings. Scholen en openbaar vervoer in de onmiddellijke omgeving (4km treinstation Landen en 4km winkelcentra Hannuit). Gelegen in een rustige straat, op een steenworp van de E40 Luik-Brussel en op 5 minuten van het station in Landen.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Naam Pand', value: 'Stelhain' },
          { description: 'Bouwjaar', value: '2020' },
          { description: 'Breedte Straatgevel', value: '8.2 m' },
          { description: 'Aantal gevels', value: '3' },
          { description: 'Staanplaatsen binnen', value: '1' },
          { description: 'Parkeerplaatsen buiten', value: '1' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '160 m²', rows: 2 },
          { description: 'Oppervlakte Woonkamer', value: '36 m²', rows: 2 },
          { description: 'Eetkamer', value: 'Ja' },
          { description: 'Type Keuken', value: 'Hyper Uitgerust', rows: 2 },
          { description: 'Slaapkamers', value: '3' },
          { description: 'Opp. slaapkamer 1', value: '14 m²' },
          { description: 'Opp. slaapkamer 2', value: '11 m²' },
          { description: 'Opp. slaapkamer 3', value: '13 m²' },
          { description: 'Dressing', value: 'Ja' },
          { description: 'Badkamers', value: '1' },
          { description: 'WC', value: '2' },
          { description: 'Wasplaats', value: 'Ja' },
          { description: 'Oppervlakte kelder', value: '80 m²' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte terrein', value: '434 m²' },
          { description: 'Aansluiting riolering', value: 'Ja' },
          { description: 'Gas, water & elektriciteit', value: 'Ja' },
          { description: 'Oppervlakte tuin', value: '274 m²' },
          { description: 'Oppervlakte terras', value: '10 m²' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [{ description: 'Aangepast aan handicap', value: 'Ja', rows: 2 }],
      },
      {
        title: 'Energie',
        items: [
          { description: 'E-niveau (primair)', value: '77 kWh/m²', rows: 2 },
          { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'Gas' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
          { description: 'Stedenbouwkundige bestemming', value: 'Woongebied ', rows: 2 },
        ],
      },
      {
        title: 'Financieel',
        items: [
          { description: 'Prijs', value: '&euro; 331.526' },
          { description: 'Grondprijs', value: '&euro; 88.000' },
          { description: 'Registratie', value: '&euro; 8.800' },
          { description: 'Constructieprijs', value: '&euro; 243.526' },
          { description: 'BTW op Constructie', value: '&euro; 51.140,46' },
          { description: 'Totaalprijs All-in', value: '&euro; 391.466,46' },
        ],
      },
    ],
  },
  {
    project: 'stelhainstraat',
    alias: 'lot_3',
    name: 'Lot 3',
    title: (
      <React.Fragment>
        <p className='slide-title'>Stelhainstraat</p>
        <p className='slide-title slide-title__lightblue'>Woning 3</p>
      </React.Fragment>
    ),
    location: {
      city: '3401 Walshoutem',
      street: 'Stelhainstraat 5',
    },
    price: '322.526',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>3 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>1 badkamer</p> },
      { icon: <House />, text: <p className='main-text'>165 m&sup2; ruimte</p> },
      { icon: <Land />, text: <p className='main-text'>434 m&sup2; grond</p> },
    ],
    description: {
      title: 'Prachtige energiezuinige halfopen bebouwing',
      text: 'Opp. woning: 155 m², terras: 10.40 m², carport in cederhout: 17.75 m². Gelegen in een rustige straat, op een steenworp van de E40 Luik-Brussel en op 5 minuten van het station in Landen.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Naam Pand', value: 'Stelhain' },
          { description: 'Bouwjaar', value: '2020' },
          { description: 'Breedte Straatgevel', value: '8.2 m' },
          { description: 'Aantal gevels', value: '3' },
          { description: 'Staanplaatsen binnen', value: '1' },
          { description: 'Parkeerplaatsen buiten', value: '1' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '160 m²', rows: 2 },
          { description: 'Oppervlakte Woonkamer', value: '36 m²', rows: 2 },
          { description: 'Eetkamer', value: 'Ja' },
          { description: 'Type Keuken', value: 'Hyper Uitgerust', rows: 2 },
          { description: 'Slaapkamers', value: '3' },
          { description: 'Opp. slaapkamer 1', value: '14 m²' },
          { description: 'Opp. slaapkamer 2', value: '11 m²' },
          { description: 'Opp. slaapkamer 3', value: '13 m²' },
          { description: 'Dressing', value: 'Ja' },
          { description: 'Badkamers', value: '1' },
          { description: 'WC', value: '2' },
          { description: 'Wasplaats', value: 'Ja' },
          { description: 'Oppervlakte kelder', value: '80 m²' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte terrein', value: '434 m²' },
          { description: 'Aansluiting riolering', value: 'Ja' },
          { description: 'Gas, water & elektriciteit', value: 'Ja' },
          { description: 'Oppervlakte tuin', value: '274 m²' },
          { description: 'Oppervlakte terras', value: '10 m²' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [{ description: 'Aangepast aan handicap', value: 'Ja', rows: 2 }],
      },
      {
        title: 'Energie',
        items: [
          { description: 'E-niveau (primair)', value: '77 kWh/m²', rows: 2 },
          { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'Gas' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
          { description: 'Stedenbouwkundige bestemming', value: 'Woongebied ', rows: 2 },
        ],
      },
      {
        title: 'Financieel',
        items: [
          { description: 'Prijs', value: '&euro; 331.526' },
          { description: 'Grondprijs', value: '&euro; 88.000' },
          { description: 'Constructieprijs', value: '&euro; 243.526' },
          { description: 'Registratie', value: '&euro; 6.160' },
          { description: 'BTW op Constructie', value: '&euro; 51.140,46' },
          { description: 'Totaalprijs All-in', value: '&euro; 388.826,46' },
        ],
      },
    ],
  },
  {
    project: 'stelhainstraat',
    alias: 'lot_4',
    name: 'Lot 4',
    title: (
      <React.Fragment>
        <p className='slide-title'>Stelhainstraat</p>
        <p className='slide-title slide-title__lightblue'>Woning 4</p>
      </React.Fragment>
    ),
    location: {
      city: '3401 Walshoutem',
      street: 'Stelhainstraat 3',
    },
    price: '327.526',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>3 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>1 badkamer</p> },
      { icon: <House />, text: <p className='main-text'>165 m&sup2; ruimte</p> },
      { icon: <Land />, text: <p className='main-text'>434 m&sup2; grond</p> },
    ],
    description: {
      title: 'Prachtige energiezuinige halfopen bebouwing',
      text: 'Opp. woning: 155 m², terras: 10.40 m², carport in cederhout: 17.75 m². Gelegen in een rustige straat, op een steenworp van de E40 Luik-Brussel en op 5 minuten van het station in Landen.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Naam Pand', value: 'Stelhain' },
          { description: 'Bouwjaar', value: '2020' },
          { description: 'Breedte Straatgevel', value: '8.2 m' },
          { description: 'Aantal gevels', value: '3' },
          { description: 'Staanplaatsen binnen', value: '1' },
          { description: 'Parkeerplaatsen buiten', value: '1' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '160 m²', rows: 2 },
          { description: 'Oppervlakte Woonkamer', value: '36 m²', rows: 2 },
          { description: 'Eetkamer', value: 'Ja' },
          { description: 'Type Keuken', value: 'Hyper Uitgerust', rows: 2 },
          { description: 'Slaapkamers', value: '3' },
          { description: 'Opp. slaapkamer 1', value: '14 m²' },
          { description: 'Opp. slaapkamer 2', value: '11 m²' },
          { description: 'Opp. slaapkamer 3', value: '13 m²' },
          { description: 'Dressing', value: 'Ja' },
          { description: 'Badkamers', value: '1' },
          { description: 'WC', value: '2' },
          { description: 'Wasplaats', value: 'Ja' },
          { description: 'Oppervlakte kelder', value: '80 m²' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte terrein', value: '434 m²' },
          { description: 'Aansluiting riolering', value: 'Ja' },
          { description: 'Gas, water & elektriciteit', value: 'Ja' },
          { description: 'Oppervlakte tuin', value: '274 m²' },
          { description: 'Oppervlakte terras', value: '10 m²' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [{ description: 'Aangepast aan handicap', value: 'Ja', rows: 2 }],
      },
      {
        title: 'Energie',
        items: [
          { description: 'E-niveau (primair)', value: '77 kWh/m²', rows: 2 },
          { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'Gas' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
          { description: 'Stedenbouwkundige bestemming', value: 'Woongebied ', rows: 2 },
        ],
      },
      {
        title: 'Financieel',
        items: [
          { description: 'Prijs', value: '&euro; 338.886' },
          { description: 'Grondprijs', value: '&euro; 93.000' },
          { description: 'Constructieprijs', value: '&euro; 245.526' },
          { description: 'Registratie', value: '&euro; 6.510' },
          { description: 'BTW op Constructie', value: '&euro; 51.560,46' },
          { description: 'Totaalprijs All-in', value: '&euro; 396.596,46' },
        ],
      },
    ],
  },
  {
    project: 'neerwindenstraat',
    alias: 'lot_1',
    name: '70A',
    title: (
      <React.Fragment>
        <p className='slide-title'>Neerwindenstraat</p>
        <p className='slide-title slide-title__lightblue'>Woning 2</p>
      </React.Fragment>
    ),
    location: {
      city: '3400 Neerwinden',
      street: 'Neerwindenstraat 70A',
    },
    price: '499.100',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>3 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>1 badkamer</p> },
      { icon: <House />, text: <p className='main-text'>192 m&sup2; ruimte</p> },
      { icon: <Land />, text: <p className='main-text'>137 m&sup2; tuin</p> },
    ],
    description: {
      title: 'Prachtige BEN halfopen bebouwing',
      text: 'Prachtige “BEN” (BIJNA ENERGIE NEUTRAAL) woningen (HOB) te koop. Rustig gelegen te Neerwinden, op 5 min te voet van treinstation en 10 minuten met de auto van oprit/afrit E40.(25 minuten van Leuven). Ruime woning van 200m² met alle comfort en volledige kwaliteitsvolle afwerking. Geen Wet Breyne maar betaling van de gehele som bij oplevering, dus geen voorfinanciering! Woning bevat 3 ruime slaapkamers, ruime badkamer met bad en douche, én volledig afgewerkte tuin. Ruime living met open keuken (Keukens Buvé) ter waarde van 18.150 €.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Naam Pand', value: 'Neerwindenstraat' },
          { description: 'Bouwjaar', value: '2024' },
          // { description: 'Breedte Straatgevel', value: '8.2 m' },
          { description: 'Aantal gevels', value: '3' },
          // { description: 'Staanplaatsen binnen', value: '1' },
          { description: 'Parkeerplaatsen buiten', value: '2' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '192 m²', rows: 2 },
          { description: 'Oppervlakte Woonkamer', value: '38.5 m²', rows: 2 },
          { description: 'Eetkamer', value: 'Ja' },
          { description: 'Type Keuken', value: 'Uitgerust', rows: 2 },
          { description: 'Slaapkamers', value: '3' },
          { description: 'Opp. slaapkamer 1', value: '11.9 m²' },
          { description: 'Opp. slaapkamer 2', value: '10.4 m²' },
          { description: 'Opp. slaapkamer 3', value: '13.1 m²' },
          { description: 'Badkamers', value: '1' },
          { description: 'WC', value: '2' },
          { description: 'Wasplaats', value: 'Ja' },
          { description: 'Kelder', value: 'Neen' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte woning', value: '326.6 m²' },
          { description: 'Aansluiting riolering', value: 'Ja' },
          { description: 'Gas, water & elektriciteit', value: 'Ja' },
          { description: 'Oppervlakte tuin', value: '134.8 m²' },
          { description: 'Oppervlakte terras', value: '17.8 m²' },
        ],
      },
      /*
      {
        title: 'Faciliteiten',
        items: [{ description: 'Aangepast aan handicap', value: 'Ja', rows: 2 }],
      },
      */
      {
        title: 'Energie',
        items: [
          // { description: 'E-niveau (primair)', value: '77 kWh/m²', rows: 2 },
          // { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'VV' },
          { description: 'Fotovoltaïsche zonnepanelen', value: '12 stuks', rows: 2 },
          { description: 'Driedubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
          { description: 'Stedenbouwkundige bestemming', value: 'Woongebied ', rows: 2 },
        ],
      },
      {
        title: 'Financieel',
        items: [
          { description: 'Prijs', value: '&euro; 499.100' },
          { description: 'Grondprijs', value: '&euro; 102.500' },
          { description: 'Constructieprijs', value: '&euro; 396.600' },
          { description: 'Registratie', value: '&euro; 12.300' },
          { description: 'BTW op Constructie', value: '&euro; 23.796' },
          { description: 'Totaalprijs All-in', value: '&euro; 535.196' },
        ],
      },
    ],
  },
  {
    project: 'neerwindenstraat',
    alias: 'lot_2',
    name: '70',
    title: (
      <React.Fragment>
        <p className='slide-title'>Neerwindenstraat</p>
        <p className='slide-title slide-title__lightblue'>Woning 1</p>
      </React.Fragment>
    ),
    location: {
      city: '3400 Neerwinden',
      street: 'Neerwindenstraat 70',
    },
    price: '499.900',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>3 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>1 badkamer</p> },
      { icon: <House />, text: <p className='main-text'>192 m&sup2; ruimte</p> },
      { icon: <Land />, text: <p className='main-text'>137 m&sup2; tuin</p> },
    ],
    description: {
      title: 'Prachtige BEN halfopen bebouwing',
      text: 'Prachtige “BEN” (BIJNA ENERGIE NEUTRAAL) woningen (HOB) te koop. Rustig gelegen te Neerwinden, op 5 min te voet van treinstation en 10 minuten met de auto van oprit/afrit E40.(25 minuten van Leuven). Ruime woning van 200m² met alle comfort en volledige kwaliteitsvolle afwerking. Geen Wet Breyne maar betaling van de gehele som bij oplevering, dus geen voorfinanciering! Woning bevat 3 ruime slaapkamers, ruime badkamer met bad en douche, én volledig afgewerkte tuin. Ruime living met open keuken (Keukens Buvé) ter waarde van 18.150 €.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Naam Pand', value: 'Neerwindenstraat' },
          { description: 'Bouwjaar', value: '2024' },
          // { description: 'Breedte Straatgevel', value: '8.2 m' },
          { description: 'Aantal gevels', value: '3' },
          // { description: 'Staanplaatsen binnen', value: '1' },
          { description: 'Parkeerplaatsen buiten', value: '2' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '192 m²', rows: 2 },
          { description: 'Oppervlakte Woonkamer', value: '39 m²', rows: 2 },
          { description: 'Eetkamer', value: 'Ja' },
          { description: 'Type Keuken', value: 'Uitgerust', rows: 2 },
          { description: 'Slaapkamers', value: '3' },
          { description: 'Opp. slaapkamer 1', value: '11.4 m²' },
          { description: 'Opp. slaapkamer 2', value: '15.8 m²' },
          { description: 'Opp. slaapkamer 3', value: '10.0 m²' },
          { description: 'Badkamers', value: '1' },
          { description: 'WC', value: '2' },
          { description: 'Wasplaats', value: 'Ja' },
          { description: 'Kelder', value: 'Neen' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte woning', value: '327.2 m²' },
          { description: 'Aansluiting riolering', value: 'Ja' },
          { description: 'Gas, water & elektriciteit', value: 'Ja' },
          { description: 'Oppervlakte tuin', value: '137.3 m²' },
          { description: 'Oppervlakte terras', value: '18 m²' },
        ],
      },
      /*
      {
        title: 'Faciliteiten',
        items: [{ description: 'Aangepast aan handicap', value: 'Ja', rows: 2 }],
      },
      */
      {
        title: 'Energie',
        items: [
          // { description: 'E-niveau (primair)', value: '77 kWh/m²', rows: 2 },
          // { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'VV' },
          { description: 'Fotovoltaïsche zonnepanelen', value: '12 stuks', rows: 2 },
          { description: 'Driedubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
          { description: 'Stedenbouwkundige bestemming', value: 'Woongebied ', rows: 2 },
        ],
      },
      {
        title: 'Financieel',
        items: [
          { description: 'Prijs', value: '&euro; 499.900' },
          { description: 'Grondprijs', value: '&euro; 102.500' },
          { description: 'Constructieprijs', value: '&euro; 397.400' },
          { description: 'Registratie', value: '&euro; 12.300' },
          { description: 'BTW op Constructie', value: '&euro; 23.844' },
          { description: 'Totaalprijs All-in', value: '&euro; 536.44' },
        ],
      },
    ],
  },
  {
    project: 'vuurmolen',
    alias: 'lot_1',
    name: 'Voormolen AO1',
    title: (
      <React.Fragment>
        <p className='slide-title'>Vuurmolen</p>
        <p className='slide-title slide-title__lightblue'>Woning 1</p>
      </React.Fragment>
    ),
    location: {
      city: '3350 Linter',
      street: 'Sint-Truidensesteenweg 207',
    },
    price: '499.900',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>3 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>2 badkamers</p> },
      { icon: <House />, text: <p className='main-text'>220 m&sup2; ruimte</p> },
      { icon: <Floor />, text: <p className='main-text'>3 verdiepingen</p> },
    ],
    description: {
      title: 'Prachtige BEN ren ovatie-woning',
      text: 'Prachtige “BEN” (BIJNA ENERGIE NEUTRAAL) woningen te koop. Renovatie en herbestemming van twee historische panden tot 5 woonentiteiten. Rustig gelegen te Linter.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Naam Pand', value: 'Vuurmolen' },
          { description: 'Bouwjaar', value: '2024' },
          { description: 'Aantal gevels', value: '3' },
          { description: 'Parkeerplaatsen buiten', value: '1' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '220 m²', rows: 2 },
          { description: 'Oppervlakte Woonkamer', value: '18 m²', rows: 2 },
          { description: 'Eetkamer', value: '17 m²' },
          { description: 'Type Keuken', value: 'Uitgerust', rows: 2 },
          { description: 'Slaapkamers', value: '3' },
          { description: 'Opp. slaapkamer 1', value: '16.5 m²' },
          { description: 'Opp. slaapkamer 2', value: '11.6 m²' },
          { description: 'Opp. slaapkamer 3', value: '12.4 m²' },
          { description: 'Badkamers', value: '2' },
          { description: 'WC', value: '2' },
          { description: 'Wasplaats', value: '6.7 m²' },
          { description: 'Kelder', value: 'Neen' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte woning', value: '220 m²' },
          { description: 'Aansluiting riolering', value: 'Ja' },
          { description: 'Gas, water & elektriciteit', value: 'Ja' },
          // { description: 'Oppervlakte terras', value: '18 m²' },
          { description: 'Gem. Tuin', value: 'Inbegrepen' },
          { description: 'Gem. Zwemvijver', value: 'Inbegrepen' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [
          { description: 'Gemeenschappelijke Tuin', value: 'Voorzien', rows: 2 },
          { description: 'Gemeenschappelijke Zwemvijver', value: 'Voorzien', rows: 2 },
        ],
      },
      {
        title: 'Energie',
        items: [
          // { description: 'E-niveau (primair)', value: '77 kWh/m²', rows: 2 },
          // { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'VV + WP' },
          { description: 'Ventilatiesysteem', value: 'Type D' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
          { description: 'Stedenbouwkundige bestemming', value: 'Woongebied ', rows: 2 },
        ],
      },
      {
        title: 'Financieel',
        items: [
          { description: 'Prijs', value: '&euro; 415.580' },
          { description: 'Grondprijs', value: '&euro; 95.480' },
          { description: 'Constructieprijs', value: '&euro; 320.100' },
          { description: 'Registratie', value: '&euro; 11.458' },
          { description: 'BTW op Constructie', value: '&euro; 24.935' },
          { description: 'Totaalprijs All-in', value: '&euro; 446.244' },
        ],
      },
    ],
  },
  {
    project: 'vuurmolen',
    alias: 'lot_2',
    name: 'Voormolen AO2',
    title: (
      <React.Fragment>
        <p className='slide-title'>Vuurmolen</p>
        <p className='slide-title slide-title__lightblue'>Woning 2</p>
      </React.Fragment>
    ),
    location: {
      city: '3350 Linter',
      street: 'Sint-Truidensesteenweg 207',
    },
    price: '499.900',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>4 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>2 badkamers</p> },
      { icon: <House />, text: <p className='main-text'>240 m&sup2; ruimte</p> },
      { icon: <Floor />, text: <p className='main-text'>3 verdiepingen</p> },
    ],
    description: {
      title: 'Prachtige BEN ren ovatie-woning',
      text: 'Prachtige “BEN” (BIJNA ENERGIE NEUTRAAL) woningen te koop. Renovatie en herbestemming van twee historische panden tot 5 woonentiteiten. Rustig gelegen te Linter.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Naam Pand', value: 'Vuurmolen' },
          { description: 'Bouwjaar', value: '2024' },
          { description: 'Aantal gevels', value: '2' },
          { description: 'Aantal verdiepingen', value: '3' },
          { description: 'Parkeerplaatsen buiten', value: '1' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '240 m²', rows: 2 },
          { description: 'Oppervlakte Woonkamer', value: '31 m²', rows: 2 },
          { description: 'Eetkamer', value: '11 m²' },
          { description: 'Type Keuken', value: 'Uitgerust', rows: 2 },
          { description: 'Slaapkamers', value: '4' },
          { description: 'Opp. slaapkamer 1', value: '13.6 m²' },
          { description: 'Opp. slaapkamer 2', value: '11.1 m²' },
          { description: 'Opp. slaapkamer 3', value: '11.0 m²' },
          { description: 'Opp. slaapkamer 4', value: '23.3 m²' },
          { description: 'Opp. Dressing', value: '7.6 m²' },
          { description: 'Badkamers', value: '2' },
          { description: 'WC', value: '4' },
          { description: 'Wasplaats', value: '5.0 m²' },
          { description: 'Kelder', value: 'Neen' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte woning', value: '240 m²' },
          { description: 'Aansluiting riolering', value: 'Ja' },
          { description: 'Gas, water & elektriciteit', value: 'Ja' },
          // { description: 'Oppervlakte terras', value: '18 m²' },
          { description: 'Gem. Tuin', value: 'Inbegrepen' },
          { description: 'Gem. Zwemvijver', value: 'Inbegrepen' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [
          { description: 'Gemeenschappelijke Tuin', value: 'Voorzien', rows: 2 },
          { description: 'Gemeenschappelijke Zwemvijver', value: 'Voorzien', rows: 2 },
        ],
      },
      {
        title: 'Energie',
        items: [
          // { description: 'E-niveau (primair)', value: '77 kWh/m²', rows: 2 },
          // { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'VV + WP' },
          { description: 'Ventilatiesysteem', value: 'Type D' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
          { description: 'Stedenbouwkundige bestemming', value: 'Woongebied ', rows: 2 },
        ],
      },
      {
        title: 'Financieel',
        items: [
          { description: 'Prijs', value: '&euro; 453.360' },
          { description: 'Grondprijs', value: '&euro; 104.160' },
          { description: 'Constructieprijs', value: '&euro; 349.200' },
          { description: 'Registratie', value: '&euro; 12.499' },
          { description: 'BTW op Constructie', value: '&euro; 20.952' },
          { description: 'Totaalprijs All-in', value: '&euro; 486.811' },
        ],
      },
    ],
  },
  {
    project: 'vuurmolen',
    alias: 'lot_3',
    name: 'Voormolen AO3',
    title: (
      <React.Fragment>
        <p className='slide-title'>Vuurmolen</p>
        <p className='slide-title slide-title__lightblue'>Woning 3</p>
      </React.Fragment>
    ),
    location: {
      city: '3350 Linter',
      street: 'Sint-Truidensesteenweg 207',
    },
    price: '499.900',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>4 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>2 badkamers</p> },
      { icon: <House />, text: <p className='main-text'>325 m&sup2; ruimte</p> },
      { icon: <Floor />, text: <p className='main-text'>3 verdiepingen</p> },
    ],
    description: {
      title: 'Prachtige BEN ren ovatie-woning',
      text: 'Prachtige “BEN” (BIJNA ENERGIE NEUTRAAL) woningen te koop. Renovatie en herbestemming van twee historische panden tot 5 woonentiteiten. Rustig gelegen te Linter.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Naam Pand', value: 'Vuurmolen' },
          { description: 'Bouwjaar', value: '2024' },
          { description: 'Aantal gevels', value: '3' },
          { description: 'Aantal verdiepingen', value: '3' },
          { description: 'Parkeerplaatsen buiten', value: '1' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '325 m²', rows: 2 },
          { description: 'Oppervlakte Woonkamer', value: '26 m²', rows: 2 },
          { description: 'Eetkamer', value: '22 m²' },
          { description: 'Type Keuken', value: 'Uitgerust', rows: 2 },
          { description: 'Slaapkamers', value: '4' },
          { description: 'Opp. slaapkamer 1', value: '23.1 m²' },
          { description: 'Opp. slaapkamer 2', value: '14.9 m²' },
          { description: 'Opp. slaapkamer 3', value: '12.5 m²' },
          { description: 'Opp. slaapkamer 4', value: '12.4 m²' },
          { description: 'Opp. Dressing', value: '14.4 m²' },
          { description: 'Badkamers', value: '2' },
          { description: 'WC', value: '3' },
          { description: 'Wasplaats', value: '6.1 m²' },
          { description: 'Kelder', value: 'Neen' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte woning', value: '325 m²' },
          { description: 'Aansluiting riolering', value: 'Ja' },
          { description: 'Gas, water & elektriciteit', value: 'Ja' },
          // { description: 'Oppervlakte terras', value: '18 m²' },
          { description: 'Gem. Tuin', value: 'Inbegrepen' },
          { description: 'Gem. Zwemvijver', value: 'Inbegrepen' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [
          { description: 'Gemeenschappelijke Tuin', value: 'Voorzien', rows: 2 },
          { description: 'Gemeenschappelijke Zwemvijver', value: 'Voorzien', rows: 2 },
        ],
      },
      {
        title: 'Energie',
        items: [
          // { description: 'E-niveau (primair)', value: '77 kWh/m²', rows: 2 },
          // { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'VV + WP' },
          { description: 'Ventilatiesysteem', value: 'Type D' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
          { description: 'Stedenbouwkundige bestemming', value: 'Woongebied ', rows: 2 },
        ],
      },
      {
        title: 'Financieel',
        items: [
          { description: 'Prijs', value: '&euro; 578.000' },
          { description: 'Grondprijs', value: '&euro; 141.050' },
          { description: 'Constructieprijs', value: '&euro; 436.950' },
          { description: 'Registratie', value: '&euro; 16.926' },
          { description: 'BTW op Constructie', value: '&euro; 26.217' },
          { description: 'Totaalprijs All-in', value: '&euro; 621.143' },
        ],
      },
    ],
  },
  {
    project: 'vuurmolen',
    alias: 'lot_4',
    name: 'Voormolen B01',
    title: (
      <React.Fragment>
        <p className='slide-title'>Vuurmolen</p>
        <p className='slide-title slide-title__lightblue'>Woning 4</p>
      </React.Fragment>
    ),
    location: {
      city: '3350 Linter',
      street: 'Sint-Truidensesteenweg 207',
    },
    price: '499.900',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>2 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>1 badkamer</p> },
      { icon: <House />, text: <p className='main-text'>173 m&sup2; ruimte</p> },
      { icon: <Floor />, text: <p className='main-text'>3 verdiepingen</p> },
    ],
    description: {
      title: 'Prachtige BEN ren ovatie-woning',
      text: 'Prachtige “BEN” (BIJNA ENERGIE NEUTRAAL) woningen te koop. Renovatie en herbestemming van twee historische panden tot 5 woonentiteiten. Rustig gelegen te Linter.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Naam Pand', value: 'Vuurmolen' },
          { description: 'Bouwjaar', value: '2024' },
          { description: 'Aantal gevels', value: '3' },
          { description: 'Aantal verdiepingen', value: '3' },
          { description: 'Parkeerplaatsen buiten', value: '1' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '173 m²', rows: 2 },
          { description: 'Oppervlakte Woonkamer', value: '19 m²', rows: 2 },
          { description: 'Eetkamer', value: '8 m²' },
          { description: 'Type Keuken', value: 'Uitgerust', rows: 2 },
          { description: 'Slaapkamers', value: '2' },
          { description: 'Opp. slaapkamer 1', value: '7.7 m²' },
          { description: 'Opp. slaapkamer 2', value: '24.3 m²' },
          { description: 'Badkamers', value: '1' },
          { description: 'WC', value: '2' },
          { description: 'Wasplaats', value: '4.9 m²' },
          { description: 'Kelder', value: 'Neen' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte woning', value: '173 m²' },
          { description: 'Aansluiting riolering', value: 'Ja' },
          { description: 'Gas, water & elektriciteit', value: 'Ja' },
          // { description: 'Oppervlakte terras', value: '18 m²' },
          { description: 'Gem. Tuin', value: 'Inbegrepen' },
          { description: 'Gem. Zwemvijver', value: 'Inbegrepen' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [
          { description: 'Gemeenschappelijke Tuin', value: 'Voorzien', rows: 2 },
          { description: 'Gemeenschappelijke Zwemvijver', value: 'Voorzien', rows: 2 },
        ],
      },
      {
        title: 'Energie',
        items: [
          // { description: 'E-niveau (primair)', value: '77 kWh/m²', rows: 2 },
          // { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'VV + WP' },
          { description: 'Ventilatiesysteem', value: 'Type D' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
          { description: 'Stedenbouwkundige bestemming', value: 'Woongebied ', rows: 2 },
        ],
      },
      {
        title: 'Financieel',
        items: [
          { description: 'Prijs', value: '&euro; 398.500' },
          { description: 'Grondprijs', value: '&euro; 75.082' },
          { description: 'Constructieprijs', value: '&euro; 323.418' },
          { description: 'Registratie', value: '&euro; 9.010' },
          { description: 'BTW op Constructie', value: '&euro; 19.405' },
          { description: 'Totaalprijs All-in', value: '&euro; 426.915' },
        ],
      },
    ],
  },
  {
    project: 'vuurmolen',
    alias: 'lot_5',
    name: 'Voormolen B02',
    title: (
      <React.Fragment>
        <p className='slide-title'>Vuurmolen</p>
        <p className='slide-title slide-title__lightblue'>Woning 5</p>
      </React.Fragment>
    ),
    location: {
      city: '3350 Linter',
      street: 'Sint-Truidensesteenweg 207',
    },
    price: '499.900',
    overview: [
      { icon: <Bed />, text: <p className='main-text'>3 slaapkamers</p> },
      { icon: <Bath />, text: <p className='main-text'>2 badkamers</p> },
      { icon: <House />, text: <p className='main-text'>300 m&sup2; ruimte</p> },
      { icon: <Floor />, text: <p className='main-text'>3 verdiepingen</p> },
    ],
    description: {
      title: 'Prachtige BEN ren ovatie-woning',
      text: 'Prachtige “BEN” (BIJNA ENERGIE NEUTRAAL) woningen te koop. Renovatie en herbestemming van twee historische panden tot 5 woonentiteiten. Rustig gelegen te Linter.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Naam Pand', value: 'Vuurmolen' },
          { description: 'Bouwjaar', value: '2024' },
          { description: 'Aantal gevels', value: '3' },
          { description: 'Aantal verdiepingen', value: '3' },
          { description: 'Parkeerplaatsen buiten', value: '1' },
        ],
      },
      {
        title: 'Binnenshuis',
        items: [
          { description: 'Bewoonbare oppervlakte', value: '300 m²', rows: 2 },
          { description: 'Oppervlakte Woonkamer', value: '24 m²', rows: 2 },
          { description: 'Eetkamer', value: '23 m²' },
          { description: 'Type Keuken', value: 'Uitgerust', rows: 2 },
          { description: 'Slaapkamers', value: '3' },
          { description: 'Opp. slaapkamer 1', value: '11.5 m²' },
          { description: 'Opp. slaapkamer 2', value: '11.3 m²' },
          { description: 'Opp. slaapkamer 3', value: '17.5 m²' },
          { description: 'Badkamers', value: '2' },
          { description: 'WC', value: '2' },
          { description: 'Wasplaats', value: '6.1 m²' },
          { description: 'Kelder', value: 'Neen' },
        ],
      },
      {
        title: 'Buitenkant',
        items: [
          { description: 'Oppervlakte woning', value: '300 m²' },
          { description: 'Aansluiting riolering', value: 'Ja' },
          { description: 'Gas, water & elektriciteit', value: 'Ja' },
          // { description: 'Oppervlakte terras', value: '18 m²' },
          { description: 'Gem. Tuin', value: 'Inbegrepen' },
          { description: 'Gem. Zwemvijver', value: 'Inbegrepen' },
        ],
      },
      {
        title: 'Faciliteiten',
        items: [
          { description: 'Gemeenschappelijke Tuin', value: 'Voorzien', rows: 2 },
          { description: 'Gemeenschappelijke Zwemvijver', value: 'Voorzien', rows: 2 },
        ],
      },
      {
        title: 'Energie',
        items: [
          // { description: 'E-niveau (primair)', value: '77 kWh/m²', rows: 2 },
          // { description: 'Energieklasse', value: 'A' },
          { description: 'Verwarmingstype', value: 'VV + WP' },
          { description: 'Ventilatiesysteem', value: 'Type D' },
          { description: 'Fotovoltaïsche zonnepanelen', value: 'Ja', rows: 2 },
          { description: 'Dubbel glas', value: 'Ja' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning ontvangen', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
          { description: 'Stedenbouwkundige bestemming', value: 'Woongebied ', rows: 2 },
        ],
      },
      {
        title: 'Financieel',
        items: [
          { description: 'Prijs', value: '&euro; 531.700' },
          { description: 'Grondprijs', value: '&euro; 130.200' },
          { description: 'Constructieprijs', value: '&euro; 401.500' },
          { description: 'Registratie', value: '&euro; 15.624' },
          { description: 'BTW op Constructie', value: '&euro; 24.090' },
          { description: 'Totaalprijs All-in', value: '&euro; 571.414' },
        ],
      },
    ],
  },
];

export function getHouses() {
  return houses;
}
