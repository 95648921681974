import React from 'react';
import _ from 'lodash';
import { getHouses } from './houses';
import { getLands } from './lands';
import { getRentals } from './rentals';
import { getApps } from './appartments';
import { Bed, House, Land, Bath, Note, Euro } from '../../components/images/icons';

// const img = {
//   alt: '',
//   size: {
//     width: 0,
//     height: 0
//   },
//   img: '',
//   thumb: ''
// };

const projects = [
  {
    name: 'Neerwindenstraat',
    alias: 'neerwindenstraat',
    taget: 'neerwindenstraat',
    title: (
      <React.Fragment>
        <p className='slide-title'>Project</p>
        <p className='slide-title slide-title__lightblue'>Neerwindenstraat</p>
      </React.Fragment>
    ),
    card: {
      alt: 'Straatbeeld',
    },
    images: [
      {
        alt: 'Straatbeeld',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Woning Links',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Woning Rechts',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Straatbeeld Recht',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Woonkamer Rechts',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Woonkamer Rechts 2',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Woonkamer Rechts 3',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Keuken Rechts',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Eetkamer Rechts',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Sfeerbeeld Rechts',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Keuken Rechts 2',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Keuken Rechts 3',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Sfeerbeeld Woonkamer',
        size: {
          width: 1920,
          height: 1080,
        },
      },
    ],
    location: {
      city: '3400 Landen',
      street: 'Neerwindenstraat',
      pos: { lat: 50.76844, lng: 5.039693 },
    },
    price: 'nvt',
    lots: {
      category: 'Woningen',
      items: [
        {
          alias: 'lot_1',
          price: '499.100',
          // allin: 'nvt',
          items: ['3 slaapkamers', '192 m²'],
        },
        {
          alias: 'lot_2',
          price: '499.900',
          // allin: 'nvt',
          items: ['3 slaapkamers', '192 m²'],
        },
      ],
    },
    overview: [
      { icon: <Bed />, text: <p className='main-text'>3 slaapkamers</p> },
      { icon: <House />, text: <p className='main-text'>192 m&sup2; ruimte</p> },
      { icon: <Note />, text: <p className='main-text'>Oktober 2024</p> },
    ],
    description: {
      title: 'Prachtige BEN-woning',
      text: 'Prachtige “BEN” (BIJNA ENERGIE NEUTRAAL) woningen (HOB) te koop aan 6% ( mits voldoen aan criteria en aankoop voor eind 2024). Rustig gelegen te Neerwinden, op 5 min te voet van treinstation en 10 minuten met de auto van oprit/afrit E40.(25 minuten van Leuven). Ruime woningen van 200m² met alle comfort en volledige kwaliteitsvolle afwerking.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Beschikbaarheid', value: 'Oktober 2024', rows: 2 },
          { description: 'Naam Pand', value: 'Neerwindenstraat' },
          { description: 'Bouwjaar', value: '2024' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
          { description: 'Overstromingsgevoelig gebied', value: 'Neen', rows: 2 },
        ],
      },
      {
        title: 'Financieel',
        items: [
          { description: 'Notariskosten', value: 'Exclusief' },
          { description: 'Registratiekosten grond', value: 'Exclusief 12%' },
          { description: 'BTW', value: '6%' },
        ],
      },
    ],
  },
  {
    name: 'Vuurmolen',
    alias: 'vuurmolen',
    taget: 'vuurmolen',
    title: (
      <React.Fragment>
        <p className='slide-title'>Project</p>
        <p className='slide-title slide-title__lightblue'>Vuurmolen</p>
      </React.Fragment>
    ),
    card: {
      alt: 'Tuin',
    },
    images: [
      {
        alt: 'Woonkamer',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Woonkamer Trap',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Leefruimte',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Eetkamer',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Bureau',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Terras',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Slaapkamer Bad',
        size: {
          width: 2000,
          height: 2000,
        },
      },
      {
        alt: 'Woonkamer Terras',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Keuken',
        size: {
          width: 2000,
          height: 2000,
        },
      },
      {
        alt: 'Bureau',
        size: {
          width: 2000,
          height: 2000,
        },
      },
      {
        alt: 'Bureau',
        size: {
          width: 2000,
          height: 2000,
        },
      },
      {
        alt: 'Woonkamer',
        size: {
          width: 2000,
          height: 2000,
        },
      },
      {
        alt: 'Slaapkamer',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Woonkamer',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Slaapkamer',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Zwemvijver',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Inkom',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Zwemvijver',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Terras',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Keuken en Eetkamer',
        size: {
          width: 2000,
          height: 2000,
        },
      },
      {
        alt: 'Badkamer',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Wandelpad',
        size: {
          width: 1920,
          height: 1080,
        },
      },
    ],
    location: {
      city: '3350 Linter',
      street: 'Sint-Truidensesteenweg',
      pos: { lat: 50.803698, lng: 5.063205 },
    },
    price: '415.000 - 531.700',
    lots: {
      category: 'Woningen',
      items: [
        {
          alias: 'lot_1',
          price: '415.580',
          // allin: 'nvt',
          items: ['3 slaapkamers', '220 m²'],
        },
        {
          alias: 'lot_2',
          price: '453.360',
          // allin: 'nvt',
          items: ['4 slaapkamers', '240 m²'],
        },
        {
          alias: 'lot_3',
          price: '578.000',
          // allin: 'nvt',
          items: ['4 slaapkamers', '325 m²'],
        },
        {
          alias: 'lot_4',
          price: '398.500',
          // allin: 'nvt',
          items: ['2 slaapkamers', '173 m²'],
        },
        {
          alias: 'lot_5',
          price: '531.700',
          // allin: 'nvt',
          items: ['3 slaapkamers', '300 m²'],
        },
      ],
    },
    overview: [
      { icon: <Bed />, text: <p className='main-text'>2-4 slaapkamers</p> },
      { icon: <House />, text: <p className='main-text'>173-325 m&sup2; ruimte</p> },
      { icon: <Note />, text: <p className='main-text'>Eind 2024</p> },
    ],
    description: {
      title: 'Prachtig BEN renovatie-woning',
      text: 'Prachtige energiezuinige halfopen woning met o.a. drie slaapkamers, vloerverwarming en zonnepanelen. Binnenafwerking naar keuze.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Beschikbaarheid', value: 'Eind 2024', rows: 2 },
          { description: 'Naam Pand', value: 'Vuurmolen' },
          { description: 'Bouwjaar', value: '2024' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
          { description: 'Overstromingsgevoelig gebied', value: 'Neen', rows: 2 },
        ],
      },
      {
        title: 'Financieel',
        items: [
          { description: 'Notariskosten', value: 'Exclusief' },
          { description: 'Registratiekosten grond', value: 'Exclusief' },
          { description: 'BTW', value: '6%' },
        ],
      },
    ],
  },
  {
    name: 'Stelhainstraat',
    alias: 'stelhainstraat',
    taget: 'stelhainstraat',
    title: (
      <React.Fragment>
        <p className='slide-title'>Project</p>
        <p className='slide-title slide-title__lightblue'>Stelhainstraat</p>
      </React.Fragment>
    ),
    card: {
      alt: 'Straatbeeld Small',
      img: (
        <svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='545' height='545' viewBox='0 0 545 545'>
          <filter id='b'>
            <feGaussianBlur stdDeviation='35' />
          </filter>{' '}
          <rect width='100%' height='100%' fill='#938e84' />{' '}
          <g filter='url(#b)'>
            <g transform='scale(2.128906) translate(0.5 0.5)'>
              {' '}
              <g transform='translate(152.499177 23.344422) rotate(189.593584) scale(219.377047 53.352866)'>
                <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
              </g>{' '}
              <ellipse fill='#564b34' fillOpacity='0.501961' cx='97' cy='237' rx='174' ry='174' />{' '}
              <ellipse fill='#fff0cc' fillOpacity='0.501961' cx='130' cy='140' rx='108' ry='11' />{' '}
              <g transform='translate(163.576062 26.524139) rotate(192.650766) scale(220.794663 51.959326)'>
                <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
              </g>{' '}
              <polygon fill='#d3c6a9' fillOpacity='0.501961' points='85,98 216,115 190,173' />{' '}
              <polygon fill='#2c3308' fillOpacity='0.501961' points='175,232 177,116 99,223' />{' '}
              <rect fill='#fffad4' fillOpacity='0.501961' x='96' y='138' width='26' height='65' />{' '}
              <g transform='translate(0.000000 168.943875) rotate(280.653069) scale(119.261062 24.579441)'>
                <ellipse fill='#222524' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
              </g>{' '}
              <ellipse fill='#283203' fillOpacity='0.501961' cx='241' cy='214' rx='180' ry='16' />{' '}
              <polygon fill='#fbffff' fillOpacity='0.501961' points='97,62 -6,48 -15,15' />{' '}
              <rect fill='#fce9c6' fillOpacity='0.501961' x='176' y='116' width='20' height='78' />{' '}
              <g transform='translate(65.574967 102.035315) rotate(276.534529) scale(4.467392 54.889653)'>
                <ellipse fill='#ffffe3' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
              </g>{' '}
              <polygon
                fill='#ffffff'
                fillOpacity='0.501961'
                points='200.793334,89.354452,238.280948,82.805584,271.000000,71.238970,241.083930,107.766198'
              />{' '}
              <g transform='translate(50.123529 73.205887) rotate(196.127701) scale(51.462640 15.080801)'>
                <ellipse fill='#3d3236' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
              </g>{' '}
              <rect fill='#ffeec7' fillOpacity='0.501961' x='218' y='142' width='13' height='52' />{' '}
              <g transform='translate(255.000000 173.464295) rotate(178.945925) scale(24.707246 28.274244)'>
                <ellipse fill='#20180d' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
              </g>{' '}
              <polygon
                fill='#292900'
                fillOpacity='0.501961'
                points='207.250810,135.367846,191.569815,167.313680,201.340651,187.512668,222.778182,178.743283'
              />{' '}
              <polygon fill='#3f3434' fillOpacity='0.501961' points='249,118 70,93 189,78' />{' '}
              <g transform='translate(146 196) rotate(352) scale(86 8)'>
                <rect fill='#dfd0b5' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
              </g>{' '}
              <ellipse fill='#a4a1a8' fillOpacity='0.501961' cx='67' cy='231' rx='46' ry='12' />{' '}
              <rect fill='#252d2c' fillOpacity='0.501961' x='69' y='150' width='26' height='70' />{' '}
              <polygon
                fill='#4c4641'
                fillOpacity='0.501961'
                points='143.244183,114.947495,92.841344,108.958158,-16.000000,124.752647,145.196675,137.030480'
              />{' '}
              <rect fill='#ebd9bb' fillOpacity='0.501961' x='29' y='131' width='75' height='19' />{' '}
              <polygon fill='#0e1203' fillOpacity='0.501961' points='2,239 21,162 -16,116' />{' '}
              <g transform='translate(0 89) rotate(238) scale(52 43)'>
                <rect fill='#80817a' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
              </g>{' '}
            </g>
          </g>
        </svg>
      ),
    },
    images: [
      {
        alt: 'Straatbeeld',
        size: {
          width: 1920,
          height: 961,
        },
        img: (
          <svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='1920' height='960' viewBox='0 0 1920 961'>
            <filter id='b'>
              <feGaussianBlur stdDeviation='50' />
            </filter>{' '}
            <rect width='100%' height='100%' fill='#969288' />{' '}
            <g filter='url(#b)'>
              <g transform='scale(7.500000) translate(0.5 0.5)'>
                {' '}
                <g transform='translate(172.414070 0.000000) rotate(98.348252) scale(45.935999 255.000000)'>
                  <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <ellipse fill='#28210c' fillOpacity='0.501961' cx='89' cy='102' rx='255' ry='29' />{' '}
                <ellipse fill='#000000' fillOpacity='0.501961' cx='24' cy='90' rx='33' ry='28' />{' '}
                <ellipse fill='#ffffff' fillOpacity='0.501961' cx='18' cy='26' rx='27' ry='34' />{' '}
                <g transform='translate(187.120103 19.684166) rotate(185.833405) scale(136.833225 27.321878)'>
                  <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <g transform='translate(72.588999 48.547456) rotate(260.736182) scale(17.887233 34.913148)'>
                  <ellipse fill='#3d3229' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <rect fill='#ffffe0' fillOpacity='0.501961' x='93' y='66' width='13' height='35' />{' '}
                <rect fill='#fffdcf' fillOpacity='0.501961' x='133' y='55' width='10' height='44' />{' '}
                <g transform='translate(121.932292 44.913460) rotate(14.566475) scale(37.199341 7.578260)'>
                  <ellipse fill='#382b2a' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <g transform='translate(203.955729 54.781043) rotate(11.138353) scale(31.972517 5.720211)'>
                  <ellipse fill='#3d312a' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <g transform='translate(227.963482 110.520852) rotate(329.018384) scale(24.158337 37.993157)'>
                  <ellipse fill='#8b8279' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <g transform='translate(242.448541 40.692046) rotate(209.488646) scale(82.123625 20.101098)'>
                  <ellipse fill='#f9fcfb' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <g transform='translate(73.242013 115.382765) rotate(279.800793) scale(6.865437 23.807572)'>
                  <ellipse fill='#aea8ab' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <ellipse fill='#fffdd4' fillOpacity='0.501961' cx='79' cy='51' rx='24' ry='3' />{' '}
                <ellipse fill='#000000' fillOpacity='0.501961' cx='16' cy='98' rx='34' ry='17' />{' '}
                <rect fill='#ffecc3' fillOpacity='0.501961' x='154' y='69' width='7' height='29' />{' '}
                <rect fill='#2f310e' fillOpacity='0.501961' x='233' y='69' width='23' height='30' />{' '}
                <rect fill='#ead3a9' fillOpacity='0.501961' x='61' y='65' width='69' height='10' />{' '}
                <polygon
                  fill='#4b412b'
                  fillOpacity='0.501961'
                  points='198.209901,51.476307,166.404418,62.635523,157.584112,114.423974,173.049612,104.751010'
                />{' '}
                <g transform='translate(21 37) rotate(190) scale(42 41)'>
                  <rect fill='#ecedf2' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
                </g>{' '}
                <ellipse fill='#2a3800' fillOpacity='0.501961' cx='126' cy='106' rx='53' ry='6' />{' '}
                <g transform='translate(156 39) rotate(305) scale(16 35)'>
                  <rect fill='#f9fafa' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
                </g>{' '}
                <polygon
                  fill='#1a1f12'
                  fillOpacity='0.501961'
                  points='56.893800,71.535869,73.333934,53.489546,57.633034,51.275935,-12.455226,113.253000'
                />{' '}
                <rect fill='#ffebc1' fillOpacity='0.501961' x='193' y='61' width='21' height='16' />{' '}
                <g transform='translate(227 78) rotate(359) scale(7 28)'>
                  <rect fill='#fff3ca' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
                </g>{' '}
                <polygon
                  fill='#4a413f'
                  fillOpacity='0.501961'
                  points='84.511446,49.030770,132.642408,52.900277,47.073129,22.409644,49.643231,44.061455'
                />{' '}
                <ellipse fill='#e4ebf3' fillOpacity='0.501961' cx='131' cy='13' rx='255' ry='13' />{' '}
                <g transform='translate(174.883963 127.000000) rotate(273.118263) scale(10.224199 109.459043)'>
                  <ellipse fill='#7f747d' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <polygon fill='#b9957c' fillOpacity='0.501961' points='68,116 72,75 54,85' />{' '}
                <rect fill='#ffffd6' fillOpacity='0.501961' x='208' y='77' width='6' height='16' />{' '}
                <polygon
                  fill='#0a1400'
                  fillOpacity='0.501961'
                  points='59.925107,121.284531,49.348282,112.875263,38.631840,73.962813,-16.000000,112.978221'
                />{' '}
                <rect fill='#534a3e' fillOpacity='0.501961' x='67' y='56' width='50' height='10' />{' '}
                <g transform='translate(142 95) rotate(261) scale(3 32)'>
                  <rect fill='#ffeac4' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
                </g>{' '}
                <polygon
                  fill='#434126'
                  fillOpacity='0.501961'
                  points='128.512922,66.877673,137.512906,40.168968,128.302585,96.298295,104.020634,98.107903'
                />{' '}
                <polygon
                  fill='#525726'
                  fillOpacity='0.501961'
                  points='47.008132,68.681438,-9.686540,87.347360,-16.000000,50.009087,15.032406,65.673877'
                />{' '}
                <g transform='translate(124.928066 8.079453) rotate(363.547853) scale(31.196386 31.396056)'>
                  <ellipse fill='#e8ebee' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <polygon
                  fill='#e3d1b0'
                  fillOpacity='0.501961'
                  points='112.605127,56.355448,124.470985,54.532256,43.477501,44.135871,64.801268,51.486921'
                />{' '}
                <ellipse fill='#3b4609' fillOpacity='0.501961' cx='174' cy='101' rx='46' ry='3' />{' '}
                <rect fill='#4d3923' fillOpacity='0.501961' x='151' y='61' width='15' height='9' />{' '}
                <g transform='translate(28 0) rotate(278) scale(45 123)'>
                  <rect fill='#d2d9e3' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
                </g>{' '}
                <ellipse fill='#cec4a2' fillOpacity='0.501961' cx='114' cy='98' rx='16' ry='2' />{' '}
                <polygon fill='#efd6b5' fillOpacity='0.501961' points='136,97 149,56 133,56' />{' '}
                <rect fill='#e6d8b6' fillOpacity='0.501961' x='117' y='53' width='3' height='33' />{' '}
                <polygon fill='#afb08f' fillOpacity='0.501961' points='-5,54 18,67 26,53' />{' '}
                <polygon
                  fill='#beaf94'
                  fillOpacity='0.501961'
                  points='221.692863,83.098050,216.343583,64.159376,241.975027,78.311515,242.927754,67.441209'
                />{' '}
                <g transform='translate(181.852366 64.574639) rotate(158.201179) scale(10.651308 20.245631)'>
                  <ellipse fill='#7a6c5b' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <g transform='translate(247.164123 20.277166) rotate(424.389392) scale(38.772123 41.468206)'>
                  <ellipse fill='#f5f5ee' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <ellipse fill='#3c392a' fillOpacity='0.501961' cx='218' cy='76' rx='2' ry='22' />{' '}
                <polygon
                  fill='#cdba9d'
                  fillOpacity='0.501961'
                  points='93.079629,77.945551,100.212118,58.500425,112.510126,83.303166,95.160596,99.902856'
                />{' '}
                <ellipse fill='#323105' fillOpacity='0.501961' cx='146' cy='84' rx='6' ry='9' />{' '}
              </g>
            </g>
          </svg>
        ),
        thumb: (
          <svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='200' height='200' viewBox='0 0 200 200'>
            <filter id='b'>
              <feGaussianBlur stdDeviation='10' />
            </filter>{' '}
            <rect width='100%' height='100%' fill='#928d83' />{' '}
            <g filter='url(#b)'>
              <g transform='scale(1.000000) translate(0.5 0.5)'>
                {' '}
                <g transform='translate(119.940922 20.388962) rotate(189.946800) scale(199.000000 36.356861)'>
                  <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <ellipse fill='#554936' fillOpacity='0.501961' cx='78' cy='197' rx='148' ry='148' />{' '}
                <g transform='translate(0.000000 149.028337) rotate(202.951852) scale(33.853962 45.482735)'>
                  <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <ellipse fill='#ffeecd' fillOpacity='0.501961' cx='118' cy='109' rx='88' ry='9' />{' '}
                <g transform='translate(136.401231 19.502644) rotate(371.733267) scale(199.000000 39.540757)'>
                  <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <g transform='translate(5.608619 54.869656) rotate(101.698597) scale(48.453102 9.028766)'>
                  <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <ellipse fill='#182300' fillOpacity='0.501961' cx='143' cy='167' rx='72' ry='11' />{' '}
                <rect fill='#ffffdb' fillOpacity='0.501961' x='154' y='85' width='15' height='69' />{' '}
                <rect fill='#ffffde' fillOpacity='0.501961' x='91' y='115' width='20' height='44' />{' '}
                <polygon fill='#ecd8bd' fillOpacity='0.501961' points='83,69 128,88 27,79' />{' '}
                <ellipse fill='#3d343a' fillOpacity='0.501961' cx='66' cy='61' rx='55' ry='14' />{' '}
                <rect fill='#cec2af' fillOpacity='0.501961' x='97' y='81' width='50' height='20' />{' '}
                <polygon fill='#29260c' fillOpacity='0.501961' points='113,149 154,146 152,93' />{' '}
                <polygon
                  fill='#dcc7a7'
                  fillOpacity='0.501961'
                  points='185.097733,141.886964,94.192672,157.070868,198.183589,150.759446,194.241072,92.497741'
                />{' '}
                <polygon
                  fill='#eff4fc'
                  fillOpacity='0.501961'
                  points='205.052790,89.327392,60.887875,-11.108242,215.000000,-5.667361,215.000000,98.135310'
                />{' '}
                <rect fill='#251a1a' fillOpacity='0.501961' x='26' y='84' width='57' height='18' />{' '}
                <g transform='translate(162.300669 74.999686) rotate(198.328283) scale(28.961463 10.272459)'>
                  <ellipse fill='#382c2d' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <polygon fill='#f6dec3' fillOpacity='0.501961' points='154,88 187,89 157,130' />{' '}
                <ellipse fill='#aba6b0' fillOpacity='0.501961' cx='73' cy='181' rx='41' ry='8' />{' '}
                <polygon
                  fill='#292403'
                  fillOpacity='0.501961'
                  points='163.143829,142.079265,171.633674,120.134373,179.738399,97.474063,178.928150,147.141996'
                />{' '}
                <g transform='translate(2.469684 146.844357) rotate(160.731655) scale(13.405985 46.205558)'>
                  <ellipse fill='#000600' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <g transform='translate(43.113433 25.320676) rotate(362.575254) scale(105.599563 16.815032)'>
                  <ellipse fill='#e0e6f2' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
                </g>{' '}
                <rect fill='#222a33' fillOpacity='0.501961' x='71' y='117' width='19' height='47' />{' '}
                <ellipse fill='#32272a' fillOpacity='0.501961' cx='104' cy='74' rx='36' ry='4' />{' '}
                <polygon fill='#322826' fillOpacity='0.501961' points='15,55 65,47 22,39' />{' '}
              </g>
            </g>
          </svg>
        ),
      },
      {
        alt: 'Inplanting',
        size: {
          width: 1920,
          height: 1281,
        },
      },
      {
        alt: 'Sfeerbeeld Living',
        size: {
          width: 1920,
          height: 1281,
        },
      },
      {
        alt: 'Sfeerbeeld Keuken',
        size: {
          width: 1920,
          height: 1281,
        },
      },
      {
        alt: 'Sfeerbeeld Buiten (Donker)',
        size: {
          width: 1920,
          height: 1281,
        },
      },
      {
        alt: 'Sfeerbeeld Tuin',
        size: {
          width: 1920,
          height: 1281,
        },
      },
      {
        alt: 'Sfeerbeeld Badkamer',
        size: {
          width: 1920,
          height: 1281,
        },
      },
    ],
    location: {
      city: '3401 Walshoutem',
      street: 'Stelhainstraat',
      pos: { lat: 50.719346, lng: 5.083412 },
    },
    price: 'sold',
    lots: {
      category: 'Woningen',
      items: [
        { alias: 'lot_1', price: 'sold', allin: '388.827', items: ['3 slaapkamers', '160 m²'] },
        { alias: 'lot_2', price: 'sold', allin: '388.826', items: ['3 slaapkamers', '160 m²'] },
        { alias: 'lot_3', price: 'sold', allin: '388.826', items: ['3 slaapkamers', '160 m²'] },
        { alias: 'lot_4', price: 'sold', allin: '396.596', items: ['3 slaapkamers', '160 m²'] },
      ],
    },
    overview: [
      { icon: <Bed />, text: <p className='main-text'>3 slaapkamers</p> },
      { icon: <House />, text: <p className='main-text'>160 m&sup2; ruimte</p> },
      { icon: <Note />, text: <p className='main-text'>bechikbaar vanaf 24/12/2020</p> },
    ],
    description: {
      title: 'Prachtige energiezuinige halfopen bebouwing',
      text: 'Prachtige energiezuinige halfopen woning met o.a. drie slaapkamers, vloerverwarming en zonnepanelen. Binnenafwerking naar keuze, carport in cederhout. Gelegen in een rustige straat, op een steenworp van de E40 Luik-Brussel en op 5 minuten van het station in Landen.',
    },
    details: [
      {
        title: 'Algemeen',
        items: [
          { description: 'Beschikbaar vanaf', value: '24 dec. 2020', rows: 2 },
          { description: 'Naam Pand', value: 'Stelhain' },
          { description: 'Bouwjaar', value: '2020' },
        ],
      },
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
          { description: 'Overstromingsgevoelig gebied', value: 'Neen', rows: 2 },
        ],
      },
      {
        title: 'Financieel',
        items: [
          { description: 'Notariskosten', value: 'Exclusief' },
          { description: 'Registratiekosten grond', value: 'Exclusief' },
          { description: 'BTW', value: '21%' },
          { description: 'Notariskosten', value: 'Excl. BTW' },
          { description: 'Registratierechten', value: 'Excl. BTW' },
        ],
      },
    ],
  },
  {
    name: 'Residentie Schalkhoven',
    alias: 'residentie-schalkhoven',
    taget: 'residentie-schalkhoven',
    title: (
      <React.Fragment>
        <p className='slide-title'>Project</p>
        <p className='slide-title slide-title__lightblue'>Schalkhoven</p>
      </React.Fragment>
    ),
    card: {
      alt: 'Straatbeeld Small',
    },
    images: [
      {
        alt: 'Straatbeeld',
        size: {
          width: 1920,
          height: 990,
        },
      },
      {
        alt: 'Straatbeeld zij',
        size: {
          width: 1920,
          height: 1065,
        },
      },
      {
        alt: 'Tuin',
        size: {
          width: 1920,
          height: 1005,
        },
      },
      {
        alt: 'Tuin',
        size: {
          width: 1920,
          height: 1042,
        },
      },
      {
        alt: 'Sfeerbeeld Keuken',
        size: {
          width: 1920,
          height: 1095,
        },
      },
      {
        alt: 'Sfeerbeeld Eetkamer',
        size: {
          width: 1920,
          height: 1020,
        },
      },
      {
        alt: 'Sfeerbeeld Terras',
        size: {
          width: 1920,
          height: 960,
        },
      },
    ],
    location: {
      city: '3870 Heers',
      street: 'Burgemeesterstraat 3-5',
      pos: { lat: 50.736584, lng: 5.259114 },
    },
    price: '220.420 - 314.345',
    lots: {
      category: 'app.',
      items: [
        { alias: 'app_1', price: 'sold', items: ['2 slaapkamers', '105 m²'] },
        { alias: 'app_2', price: 'sold', items: ['2 slaapkamers', '108 m²'] },
        { alias: 'app_3', price: 'sold', items: ['2 slaapkamers', '138 m²'] },
        { alias: 'app_4', price: 'sold', items: ['2 slaapkamers', '112 m²'] },
        { alias: 'app_5', price: 'sold', items: ['2 slaapkamers', '105 m²'] },
        { alias: 'app_6', price: 'sold', items: ['2 slaapkamers', '133 m²'] },
        { alias: 'app_7', price: 'sold', items: ['2 slaapkamers', '118 m²'] },
        { alias: 'app_8', price: 'sold', items: ['2 slaapkamers', '127 m²'] },
        { alias: 'app_9', price: 'sold', items: ['1 slaapkamer', '99 m²'] },
        { alias: 'app_10', price: 'sold', items: ['2 slaapkamers', '115 m²'] },
        { alias: 'app_11', price: 'sold', items: ['2 slaapkamers', '113 m²'] },
        { alias: 'app_12', price: 'sold', items: ['2 slaapkamers', '130 m²'] },
      ],
    },
    overview: [
      { icon: <Bed />, text: <p className='main-text'>1 -2 slaapkamers</p> },
      { icon: <House />, text: <p className='main-text'>99 - 138 m&sup2; ruimte</p> },
      { icon: <Note />, text: <p className='main-text'>nu bechikbaar</p> },
    ],
    description: {
      title: 'Residentie Schalkhoven',
      text: 'Prachtig luxeproject van 12 appartementen te Mechelen Bovelingen (Heers). Dit project omvat 12 luxueus afgewerkte appartementen met grote terrassen en kelderberging. Er zijn 14 ondergrondse staanplaatsen voorzien. De appartementen op het gelijkvloers hebben naast een terras ook een privaat tuintje. Alle appartementen hebben vloerverwarming en een ventilatie D, een prachtige keuken met toestellen van AEG en een standaard voorzien composiet werkblad. Alle sanitair is van topkwaliteit en inbegrepen. Er zijn ook voor elk appartement apart zonnepanelen voorzien met private opbrengst. Vloeren, deuren naar keuze binnen standaardpakket.',
    },
    details: [
      {
        title: 'Stedenbouw',
        items: [
          { description: 'Stedenbouwkundige vergunning', value: 'Ja', rows: 2 },
          { description: 'Type bouw', value: 'Alle types' },
          { description: 'Verkavelingsvergunning', value: 'Ja' },
          { description: 'Overstromingsgevoelig gebied', value: 'Ja', rows: 2 },
        ],
      },
      {
        title: 'Financieel',
        items: [
          { description: 'Notairskosten', value: 'Exclusief' },
          { description: 'Registratiekosten grond', value: 'Exclusief' },
          { description: 'BTW', value: '21%' },
          { description: 'Registratie- en notariskosten', value: 'Exclusief BTW', rows: 2 },
        ],
      },
    ],
  },
  {
    name: 'Bouwgrond Heers',
    alias: 'bouwgrond-heers',
    taget: 'bouwgrond-heers',
    isLand: true,
    title: (
      <React.Fragment>
        <p className='slide-title'>Prachtige Bouwgrond</p>
        <p className='slide-title slide-title__lightblue'>Heers</p>
      </React.Fragment>
    ),
    card: {
      alt: 'Vooraanzicht',
    },
    images: [
      {
        alt: 'Vooraanzicht',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Achterkant',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Keuken',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Leefruimte',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Badkamer',
        size: {
          width: 1920,
          height: 1080,
        },
      },
      {
        alt: 'Inplantingsplan',
        size: {
          width: 1920,
          height: 1357,
        },
      },
      {
        alt: 'Grondplan',
        size: {
          width: 1920,
          height: 1357,
        },
      },
      {
        alt: 'Verdiepingsplan',
        size: {
          width: 1920,
          height: 1357,
        },
      },
    ],
    location: {
      city: '3870 Heers',
      street: 'Bovelingenstraat 62A',
      pos: { lat: 50.748119, lng: 5.270616 },
    },
    price: 'sold',
    overview: [
      { icon: <Land />, text: <p className='main-text'>7a 50 (750 m&sup2;)</p> },
      { icon: <Note />, text: <p className='main-text'>Onmiddelijk bechikbaar</p> },
      { icon: <span></span>, text: <p className='main-text'>Verkocht</p> },
    ],
    description: {
      title: 'Prachtige bouwgrond voor open bebouwing te Heers',
      text: 'Prachtige bouwgrond voor open-bebouwing te koop te Heers, eventueel met vergunning voor villa inbegrepen. Op dit perceel werd reeds een vergunning bekomen voor een prachtige ruime woning met garage, 3 of 4 slaapkamers, ruime badkamer, ... Zicht op beschermd natuurgebied, goede verbinding met Sint-Truiden, E40, Waremme en Luik. Landelijke ligging.',
    },
    details: [],
    downloads: [
      { description: 'Inplantingsplan', path: 'inplantingsplan' },
      { description: 'Grondplan', path: 'grondplan' },
      { description: 'Verdiepingsplan', path: 'verdiepingsplan' },
    ],
  },
];

const allLots = _.concat(getHouses(), getLands(), getRentals(), getApps());

export function getHighlights() {
  // return _.concat(projects, projects, projects);
  return projects;
}

export function getProject(alias) {
  const [project] = projects.filter((p) => p.alias === alias);

  if (project) return project;
  else return false;
}

export function getLot(projectAlias, alias) {
  const [lot] = allLots.filter((l) => l.project === projectAlias && l.alias === alias);

  if (lot) return lot;
  else return false;
}
